<template>
  <div class="container">
    <macro-enabled-editor v-show="false" name="" value="" />
    <h2 class="mb-3">Copy User Dictionary</h2>
    <button class="btn btn-primary mb-2" @click="isExtractMacroWordsOpen = true">
      Extract words from macros
    </button>
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col">
          <SelectInput
            label="User ID for source dictionary"
            v-model="sourceId"
            placeholder="Copying from..."
            :disabled="hasExtractedWords"
            :dataSource="sourceDictUsers"
            :displayExpr="userDisplayName"
            valueExpr="id"
            :idIsString="true"
            :searchExpr="['lastName', 'firstName']"
          />
        </div>
        <div class="col">
          <SelectInput
            label="User ID for target dictionary"
            v-model="targetId"
            placeholder="Copying to..."
            :dataSource="targetDictUsers"
            :displayExpr="userDisplayName"
            valueExpr="id"
            :idIsString="true"
            :searchExpr="['lastName', 'firstName']"
          />
        </div>
      </div>
      <text-area-input class="mt-5" label="Words to Add" v-model="extractedWords" />
      <div class="d-flex justify-content-end p-1">
        <button
          class="btn btn-primary"
          v-shortkey="submitShortkey"
          @shortkey="handleSubmit"
          :disabled="!targetId || isRunning"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
    <modal :status="isExtractMacroWordsOpen" @close="isExtractMacroWordsOpen = false">
      <h2>Extract Words From Result Macros</h2>
      <!-- <select-input label="Lab" :items="availableLabs" v-model="labToExtract" /> -->
      <tag-input
        label="Users"
        :dataSource="userOptions"
        :displayExpr="userDisplayName"
        valueExpr="id"
        v-model="usersToExtract"
      />
      <submit-cancel-row
        @submit="extractWordsFromMacros"
        @cancel="isExtractMacroWordsOpen = false"
        :isDisabled="!usersToExtract.length"
      />
    </modal>
  </div>
</template>

<script>
import MacroEnabledEditor from "./common/MacroEnabledEditor.vue";
import mccalmontWordList from "@/modules/mccalmontWordList";
import { altKey } from "@/modules/helpers";
import TextAreaInput from "./TextAreaInput.vue";
import { MacrosApi, SpellCheckApi, UsersApi } from "@/services";
import Modal from "./common/Modal.vue";
import SelectInput from "./common/SelectInput.vue";
import { mapState } from "vuex";
import DataSource from "devextreme/data/data_source";
import TagInput from "./common/TagInput.vue";
import { UserTypesEnum } from "@/modules/enums";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import { handleErrors } from "@/modules/handleErrors";
import { uniqBy } from "lodash";

export default {
  components: {
    MacroEnabledEditor,
    TextAreaInput,
    Modal,
    TagInput,
    SubmitCancelRow,
    SelectInput
  },
  data() {
    return {
      sourceId: "",
      targetId: "",
      isRunning: false,
      message: "",
      submitShortkey: altKey("s"),
      extractedWords: "",
      isExtractMacroWordsOpen: false,
      usersToExtract: [],
      labToExtract: null
    };
  },
  mounted() {
    this.labToExtract = this.currentLab;
  },
  methods: {
    async handleSubmit() {
      if (this.isRunning) {
        return;
      }
      if (!this.targetId) {
        window.alert("Please enter a target user ID.");
        return;
      }
      try {
        this.isRunning = true;
        this.message = "Copy started";
        let sourceList;
        if (this.parsedExtractedWords?.length) {
          sourceList = this.parsedExtractedWords.split(" ");
        } else if (!this.sourceId) {
          sourceList = mccalmontWordList;
        } else {
          const userDictionary = await SpellCheckApi.getUserWordList(this.sourceId);
          if (!userDictionary?.words?.length) {
            window.alert("Source user has no words in dictionary.");
            return;
          } else {
            sourceList = userDictionary.words;
          }
        }
        sourceList = uniqBy(
          sourceList
            .filter(e => e && /[a-z]/i.test(e) && !/^(\d+)?(x)?\d+([cm]m)?$/i.test(e))
            .map(e => e.toLowerCase())
        );
        const targetDictionary = await SpellCheckApi.getUserWordList(this.targetId);
        if (!targetDictionary) {
          const success = await SpellCheckApi.createUserDictionary(sourceList, this.targetId);
          if (!success) {
            window.alert("Error creating dictionary for target user.");
            return;
          }
        } else {
          await SpellCheckApi.addWordsToDictionary(sourceList, this.targetId);
          window.notify("Copied dictionary.");
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isRunning = false;
      }
    },
    async extractWordsFromMacros() {
      const words = await MacrosApi.extractMacroWords(this.usersToExtract, this.labToExtract);
      const isPlural = this.usersToExtract.length > 1;
      if (!words.length) {
        window.alert(
          `Selected user${isPlural ? "s" : ""} do${
            isPlural ? "" : "es"
          } not have any words to extract.`
        );
        return;
      }
      this.extractedWords = words.join("\n");
      this.isExtractMacroWordsOpen = false;
    },
    userDisplayName(data) {
      return `${data.lastName}, ${data.firstName}`;
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      availableLabs: state => state.availableLabs
    }),
    parsedExtractedWords() {
      if (!this.extractedWords?.length) {
        return "";
      }
      return this.extractedWords.replaceAll(/[^a-z0-9']/gi, " ");
    },
    hasExtractedWords() {
      return this.extractedWords.length > 0;
    },
    userOptions() {
      return new DataSource({
        store: UsersApi.labUsersStore,
        sort: "lastName",
        filter: ["userTypeId", "=", UserTypesEnum.LabUser]
      });
    },
    sourceDictUsers() {
      return new DataSource({
        store: UsersApi.labUsersStore,
        sort: "lastName",
        filter: ["userTypeId", ">", UserTypesEnum.LabClientUser]
      });
    },
    targetDictUsers() {
      return new DataSource({
        store: UsersApi.labUsersStore,
        sort: "lastName",
        filter: ["userTypeId", ">", UserTypesEnum.LabClientUser]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: $white;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}
</style>
